import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RelativeTimePipe } from '../relative-time.pipe';

import { DatePipe } from '../date.pipe';

import { LoadingComponent} from '../loading/loading.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  declarations: [LoadingComponent, RelativeTimePipe, DatePipe],
  exports: [LoadingComponent, RelativeTimePipe, DatePipe]
})
export class ComponentsModule { }
