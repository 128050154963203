import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AdMob } from '@admob-plus/ionic/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HeaderColor } from '@ionic-native/header-color/ngx';

import { AdMobFree } from '@ionic-native/admob-free/ngx'; 

import { AdmobfreeService } from './admobfree.service';

import { AuthenticateService } from './services/authentication.service';

import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';

import { IonicStorageModule } from '@ionic/storage-angular';
import { ListPageModule } from './list/list.module'; 
import { LightboxPageModule } from './lightbox/lightbox.module'; 
import { ImgboxPageModule } from './imgbox/imgbox.module'; 

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AuthService } from './auth.service';

import { ComponentsModule } from './components/components.module'; 

import { SwiperModule } from 'swiper/angular';

import * as firebase from 'firebase/app';

import { AdsenseModule } from 'ng2-adsense';


firebase.initializeApp(environment.firebase);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
	ReactiveFormsModule,
	AngularFireModule.initializeApp(environment.firebase),
	AngularFireAuthModule,
	AngularFirestoreModule, 
    AngularFireStorageModule,
	ComponentsModule,
	SwiperModule,
	LightboxPageModule,
	ImgboxPageModule,
	ListPageModule,
	// shown passing global defaults (optional)
	AdsenseModule.forRoot({
      adClient: 'ca-pub-8413312911086031',
      adSlot: 6586400513,
    }),
	IonicStorageModule.forRoot()],
  providers: [
    StatusBar,
    // SplashScreen, 
	AdMob,
	HeaderColor,
	AuthenticateService,
	AdMobFree,
	AdmobfreeService,
	AuthService,
	{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
