import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {

  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: string, ...args) { 
    return new Date(value);
  }

}
