import { Injectable } from "@angular/core";
 
import * as firebase from 'firebase/app';

import { Userinterface } from '../userinterface';

import { getAuth, EmailAuthProvider, createUserWithEmailAndPassword, reauthenticateWithCredential, updateEmail, signInWithEmailAndPassword, signInWithPopup, FacebookAuthProvider, signOut, updatePassword } from "firebase/auth";

import * as moment from 'moment';

import { Observable, of } from 'rxjs';
import { switchMap, first, map } from 'rxjs/operators';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { AngularFireAuth } from '@angular/fire/compat/auth';

 
@Injectable()
export class AuthenticateService {
	
	private user: Userinterface
	chatuser$: Observable<any>;
 
  constructor(public afAuth: AngularFireAuth,
    public afstore: AngularFirestore){

    this.chatuser$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afstore.doc<any>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }
		
  getUser() {
    return this.chatuser$.pipe(first()).toPromise();
  }	
 
  registerUser(value){
   return new Promise<any>((resolve, reject) => {
     const auth = getAuth();
     createUserWithEmailAndPassword(auth, value.email, value.password)
     .then(
       res => resolve(res), 
       err => reject(err))
   })
  }
  
	// user Services
  
  	setUser(user) {
		this.user = user
	}

	getUsername(): string { 
		return this.user.email
	}
	
	promptForCredentials(email, password) {
	 var credential = EmailAuthProvider.credential(email, password);
	 return credential;
	}
	
	reAuth(email: string, password: string) {
	
	 const auth = getAuth();
     const user = auth.currentUser;

     // TODO(you): prompt the user to re-provide their sign-in credentials
     const credential = this.promptForCredentials(email, password); 

     return reauthenticateWithCredential(user, credential).then(() => {
      // User re-authenticated.
      }).catch((error) => {
      // An error ocurred
      // ...
     });
	}

	updatePassword(newpassword: string) {
	    const auth = getAuth();

        const user = auth.currentUser;
		return updatePassword(user, newpassword).then(() => {
         // Update successful.
         }).catch((error) => {
         // An error ocurred
         // ...
        });
	}

	updateEmail(newemail: string) {
	    const auth = getAuth();
		return updateEmail(auth.currentUser, newemail).then(() => {
         // Email updated!
         // ...
        }).catch((error) => {
         // An error occurred
        // ...
      });
	}
 
 	async isAuthenticated() {
		if(this.user) return true

		const user = await this.afAuth.authState.pipe(first()).toPromise()

		if(user) {
			this.setUser({
				username: user.email,
				uid: user.uid
			})

			return true
		}
		return false
	}

	getUID(): string {
		return this.user.uid
	}
	
	// user Services
 
 
    loginUser(value){
     return new Promise<any>((resolve, reject) => {
     const auth = getAuth();
     signInWithEmailAndPassword(auth, value.email, value.password)
     .then(
       res => resolve(res),
       err => reject(err)
	   )
   })
  }
 
  logoutUser(){
    return new Promise((resolve, reject) => {
    const auth = getAuth();
    signOut(auth).then(() => {
    // Sign-out successful.
    }).catch((error) => {
    // An error happened.
    });
    })
  }
 
  userDetails(){
    const auth = getAuth();
    const user = auth.currentUser;
    return user;
  }
  
   // Sign in with Facebook
   facebookAuth() {
    return new Promise((resolve, reject) => {
    const auth = getAuth();
    signInWithPopup(auth, new FacebookAuthProvider())
    .then((result) => {
    // The signed-in user info.
    const user = result.user;
	
	// store firebase
    this.updateUser(user);

    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
    const credential = FacebookAuthProvider.credentialFromResult(result);
    const accessToken = credential.accessToken;

     // ...
    })
    })   
   

  }
 
  private updateUser(authData) {
	  
  // verifier si utilisateur deja inscrit

  this.afstore.doc('users/' + authData.uid).set({
				email : authData.email,
				username : authData.email,
				firstname : authData.displayName,
				lastname : 'bbet',
				uid : authData.uid,
				photoURL : authData.photoURL,
				timestamp: moment().format(),
			}, { merge: true }) 
 }
  
  
}
