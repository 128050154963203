import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router'
import { AuthenticateService } from './services/authentication.service'

@Injectable()
export class AuthService {

	constructor(private router: Router, private user: AuthenticateService) {

	}

	async canActivate(route) {
		if(await this.user.isAuthenticated()) {
			return true
		}

		this.router.navigate(['/login'])
		return false
	}
}
