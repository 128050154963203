import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';

@Component({
  selector: 'app-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
})
export class ListPage implements OnInit {

  constructor(private navParams: NavParams, private modalController: ModalController) { }

  ngOnInit() {
  }
  
   async Dismiss() { 
	  const result: Date = new Date();
      await this.modalController.dismiss(result);
    }

}
