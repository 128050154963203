import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
// import { Events } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HeaderColor } from '@ionic-native/header-color/ngx';
// import { FCM } from '@awesome-cordova-plugins/fcm/ngx';

import { Router } from '@angular/router';

import { AuthenticateService } from './services/authentication.service';

@Component({
  selector: 'app-root', 
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      title: 'Accueil',
      url: '',
      icon: 'home' 
    },  
    {
      title: 'Historiques',
      url: '/historique',
      icon: 'calendar'
    },
    {
      title: 'Matrices',
      url: '/matrices',
      icon: 'calculator' 
    },
    {
      title: 'Matrices/Resultats',
      url: '/mresults',
      icon: 'clipboard'
    },
    {
      title: 'Tester Banka',
      url: '/banka',
      icon: 'checkmark-circle-outline'
    },
	
    {
      title: 'Forcaster',
      url: '/fourcaster',
      icon: 'glasses'
    },
    {
      title: 'Chat',
      url: '/chat/FypfSmhmFgaPiHTDDVuR',
      icon: 'chatbox-outline'
    },
    {
      title: 'Nous contacter',
      url: '/contacts',
      icon: 'person-outline'
    },
    {
      title: 'Infos lotto',
      url: '/infos',
      icon: 'information'
    },
    {
      title: 'Connexion',
      url: '/login',
      icon: 'log-in'
    }, 	
  ];
  
  isAuth;
  username;

  constructor(
    private platform: Platform,
    // private splashScreen: SplashScreen,
    private statusBar: StatusBar,
	// private fcm: FCM,
    private router: Router,
	// public event: Events, 
	private headerColor: HeaderColor,
	private auth : AuthenticateService
    ) {
    this.initializeApp();
    // this.event.subscribe('userLogged',(data)=>{
	// this.isAuth = true;
	// this.username = data.email;
    // });
    }

    initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
	  // let status bar overlay webview
      this.statusBar.overlaysWebView(false);

      // set status bar to white
      this.statusBar.backgroundColorByHexString('#c5c3be');
	  this.headerColor.tint('#c5c3be');
      // this.splashScreen.hide(); 
	  this.isAuthenticated();
	  
    if (this.platform.is('cordova')) {
    // You're on a device, call the native plugins. Example: 
    //
// this.fcm.subscribeToTopic('lmplus');
/* 
this.fcm.getToken().then(token => {
  console.log(token);
}); */

/* this.fcm.onNotification().subscribe(data => {
  if(data.wasTapped){
    console.log("Received in background");
	this.router.navigate([data.landing_page, data.id]);
  } else {
    console.log("Received in foreground");
	this.router.navigate([data.landing_page, data.id]);
  };
}); */

/* this.fcm.onTokenRefresh().subscribe(token => {
  console.log(token);
}); */
console.log('remove fcm');
    }
	   
    });
 }
  
  
  async isAuthenticated(){
   if(await this.auth.isAuthenticated()){
	   this.isAuth = true;

	   let details = this.auth.userDetails();
	   
	   this.username = details.email;
	   console.log(this.isAuth);
	 }else{ 
	    this.isAuth = false;
		console.log(this.isAuth);
	 }	  
  }
  
  logout(){
   this.auth.logoutUser().then(res => {
      console.log('logout');
	  this.isAuth = false;
      console.log(res);
   });
  }
  
}