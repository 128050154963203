import { Injectable } from '@angular/core';

import { AdMob } from '@admob-plus/ionic/ngx';

import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AdmobfreeService {
  
 
  constructor(
    public admob:AdMob,
    public platform: Platform
  ) {
 
    this.platform.ready().then(async () => {
      await this.admob.start();

    });
  }
 
 
  async BannerAd() { 
    const banner = new this.admob.BannerAd({
      adUnitId: 'ca-app-pub-6521217912797881/7466717512',
    });
    await banner.show();  
  }
 
  async InterstitialAd() {
    const interstitial = new this.admob.InterstitialAd({
      adUnitId: 'ca-app-pub-6521217912797881/5707338317',
    });
    await interstitial.load();
    await interstitial.show();
  } 
 
  async RewardVideoAd() {
    const rewarded = new this.admob.RewardedAd({
      adUnitId: 'ca-app-pub-6521217912797881/1925728173',
    });
    await rewarded.load();
    await rewarded.show();
  }
  
  
}
