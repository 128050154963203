import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-imgbox',
  templateUrl: './imgbox.page.html',
  styleUrls: ['./imgbox.page.scss'],
})
export class ImgboxPage implements OnInit {

  @ViewChild('slider', { read: ElementRef })slider: ElementRef;
 
  sliderOpts = {
    zoom: {
      maxRatio: 5
    }
  };

  valueUrl;

  constructor(public modalController : ModalController, public navParams : NavParams) { }

  ngOnInit() {
	 this.valueUrl = this.navParams.get('uri');
	 console.log(this.navParams.get('uri'));
  }
  
  async dismiss() { 
	  const result: Date = new Date();
      await this.modalController.dismiss(result);
    }
	
  zoom(zoomIn: boolean) {
    let zoom = this.slider.nativeElement.swiper.zoom;
    if (zoomIn) {
      zoom.in();
    } else {
      zoom.out();
    }
  }


}
