import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthService } from './auth.service'

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { path: 'login', loadChildren: () => import('./login/login/login.module').then((m) => m.LoginPageModule) },
  { path: 'register', loadChildren: () => import('./register/register/register.module').then((m) => m.RegisterPageModule) },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule), canActivate: [AuthService] },
  { path: 'profile', loadChildren: () => import('./profile/profile.module').then((m) => m.ProfilePageModule), canActivate: [AuthService] },
  { path: 'chat/:id', loadChildren: () => import('./chat/chat.module').then((m) => m.ChatPageModule), canActivate: [AuthService]},
  { path: 'list', loadChildren: () => import('./list/list.module').then((m) => m.ListPageModule) },
  { path: 'matrices', loadChildren: () => import('./matrices/matrices.module').then((m) => m.MatricesPageModule) },
  { path: 'results/:pays', loadChildren: () => import('./results/results.module').then((m) => m.ResultsPageModule) },
  { path: 'mresults', loadChildren: () => import('./mresults/mresults.module').then((m) => m.MresultsPageModule) },
  { path: 'fourcaster', loadChildren: () => import('./fourcaster/fourcaster.module').then((m) => m.FourcasterPageModule), canActivate: [AuthService]},
  { path: 'resultghana', loadChildren: () => import('./resultghana/resultghana.module').then((m) => m.ResultghanaPageModule) },
  { path: 'resultci', loadChildren: () => import('./resultci/resultci.module').then((m) => m.ResultciPageModule) },
  { path: 'matriceghana', loadChildren: () => import('./matriceghana/matriceghana.module').then((m) => m.MatriceghanaPageModule) },
  { path: 'matriceci', loadChildren: () => import('./matriceci/matriceci.module').then((m) => m.MatriceciPageModule) },
  { path: 'matricei', loadChildren: () => import('./matricei/matricei.module').then((m) => m.MatriceiPageModule) },
  { path: 'pronostic', loadChildren: () => import('./pronostic/pronostic.module').then((m) => m.PronosticPageModule) },
  { path: 'banka', loadChildren: () => import('./banka/banka.module').then((m) => m.BankaPageModule)},
  { path: 'historique', loadChildren: () => import('./historique/historique.module').then((m) => m.HistoriquePageModule) },
  { path: 'lightbox', loadChildren: () => import('./lightbox/lightbox.module').then((m) => m.LightboxPageModule) },
  { path: 'selectresult', loadChildren: () => import('./selectresult/selectresult.module').then((m) => m.SelectresultPageModule) },
  { path: 'selectmatrice/:pays', loadChildren: () => import('./selectmatrice/selectmatrice.module').then((m) => m.SelectmatricePageModule) },
  { path: 'contacts', loadChildren: () => import('./contacts/contacts.module').then((m) => m.ContactsPageModule) },
  { path: 'infos', loadChildren: () => import('./infos/infos.module').then((m) => m.InfosPageModule) },
  { path: 'codesgh', loadChildren: () => import('./codesgh/codesgh.module').then((m) => m.CodesghPageModule) },
  { path: 'inconnugh', loadChildren: () => import('./inconnugh/inconnugh.module').then((m) => m.InconnughPageModule) },
  { path: 'tableaux', loadChildren: () => import('./tableaux/tableaux.module').then((m) => m.TableauxPageModule) },
  { path: 'resultsghinter', loadChildren: () => import('./resultsghinter/resultsghinter.module').then((m) => m.ResultsghinterPageModule) },
  { path: 'pyramidecroix', loadChildren: () => import('./pyramidecroix/pyramidecroix.module').then((m) => m.PyramidecroixPageModule) },
  { path: 'imgbox', loadChildren: () => import('./imgbox/imgbox.module').then((m) => m.ImgboxPageModule) },
  { path: 'banker', loadChildren: () => import('./banker/banker.module').then((m) => m.BankerPageModule) },
  { path: 'resultsfr', loadChildren: () => import('./resultsfr/resultsfr.module').then((m) => m.ResultsfrPageModule) },
  { path: 'resultsng', loadChildren: () => import('./resultsng/resultsng.module').then((m) => m.ResultsngPageModule) },
  { path: 'resultsbn', loadChildren: () => import('./resultsbn/resultsbn.module').then((m) => m.ResultsbnPageModule) },
  { path: 'resultstg', loadChildren: () => import('./resultstg/resultstg.module').then((m) => m.ResultstgPageModule) },
  { path: 'bonuses', loadChildren: () => import('./bonuses/bonuses.module').then((m) => m.BonusesPageModule) },
  { path: 'notif/:id', loadChildren: () => import('./notif/notif.module').then((m) => m.NotifPageModule) },
  { path: 'afrikplus', loadChildren: () => import('./afrikplus/afrikplus.module').then((m) => m.AfrikplusPageModule) },
  { path: 'afcountry/:pays', loadChildren: () => import('./afcountry/afcountry.module').then((m) => m.AfcountryPageModule) },
  { path: 'resultafplus/:pays', loadChildren: () => import('./resultafplus/resultafplus.module').then((m) => m.ResultafplusPageModule) },
  { path: 'matriceafplus/:pays', loadChildren: () => import('./matriceafplus/matriceafplus.module').then((m) => m.MatriceafplusPageModule) },
  { path: 'historyaf', loadChildren: () => import('./historyaf/historyaf.module').then((m) => m.HistoryafPageModule) },
  { path: 'resultsafplus/:pays', loadChildren: () => import('./resultsafplus/resultsafplus.module').then((m) => m.ResultsafplusPageModule) },
  { path: 'historygc', loadChildren: () => import('./historygc/historygc.module').then((m) => m.HistorygcPageModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
