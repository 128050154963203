import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import {ModalController, NavParams} from '@ionic/angular';

import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.page.html',
  styleUrls: ['./lightbox.page.scss'],
})
export class LightboxPage implements OnInit {

  @ViewChild('slider', { read: ElementRef })slider: ElementRef;
 
  sliderOpts = {
    zoom: {
      maxRatio: 5
    }
  };
  // Data passed in by componentProps
  
  valueUrl;
  uri;

  constructor(private storage: AngularFireStorage, private navParams: NavParams, private modalController: ModalController) { }

  ngOnInit() {
	 this.valueUrl = this.navParams.get('url');
	 console.log(this.navParams.get('url'));
	 this.getUrl(this.valueUrl);
  }
  
  async dismiss() { 
	  const result: Date = new Date();
      await this.modalController.dismiss(result);
    }
	
  zoom(zoomIn: boolean) {
    let zoom = this.slider.nativeElement.swiper.zoom;
    if (zoomIn) {
      zoom.in();
    } else {
      zoom.out();
    }
  }
	
  getUrl(value){
     const ref = this.storage.ref(value);
	 this.uri = ref.getDownloadURL();
  }

}
